/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Title, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, Image, Fullmap, FullmapWrap, Subtitle, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--40 pt--60" menu={true} parallax={false} backgroundPosition={null}>
            
            <Menu style={{"maxWidth":1316}}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Title className="title-box fs--36 title-box--invert" content={"<span style=\"font-style: italic;\">KOVY BUS s.r.o.</span>"}>
                </Title>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor1 btn-box--invert pr--20" href={"#informace-1"} tsbtn={null} target={""} content={"O nás"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--invert pr--20" href={"#informace-1"} target={""} content={"Nabízíme"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--invert pr--20" href={"#autobusy"} target={""} content={"Naše autobusy"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--invert pr--20" href={"#cena"} target={""} content={"Ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--invert pr--20" href={"#kontakt-1"} target={""} content={"Kontakt"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

            <Menu style={{"maxWidth":1355}}>
              
              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-155ymk8 --full pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"rgba(0,0,0,1)"}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/12802/e80f588b237a42cd939a49b228a222de_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/12802/e80f588b237a42cd939a49b228a222de_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/12802/e80f588b237a42cd939a49b228a222de_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/12802/e80f588b237a42cd939a49b228a222de_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/12802/e80f588b237a42cd939a49b228a222de_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/12802/e80f588b237a42cd939a49b228a222de_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/12802/e80f588b237a42cd939a49b228a222de_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/12802/e80f588b237a42cd939a49b228a222de_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86 title-box--invert lh--12 mt--02" content={"Autobusová doprava, na kterou se můžete spolehnout"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--20 pt--60" name={"informace-1"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":500}} content={"<span style=\"color: rgb(0, 0, 0);\">O nás</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":500}} content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">KOVY BUS s.r.o.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":521}} content={"<span style=\"background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);\">Jsme rodinná firma na trhu s autobusovou dopravou a máme spoustu zkušeností, spokojených firem a cestujících.<br>Naše autobusy jsou ve skvělém stavu a poskytují až 60 míst s klimatizací.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":521}} content={"<span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: bold;\">Nabízíme<br></span></span><span style=\"color: rgb(0, 0, 0);\"><br>zájezdovou a linkovou dopravu, svozovou dopravu zaměstnanců pro firmy, školní výlety, exkurze, školy v přírodě, lyžařské kurzy, plavání, firemní akce, apod..<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/12802/a23beccbdc80428b9c5ca9a1474f7b65_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/12802/a23beccbdc80428b9c5ca9a1474f7b65_s=350x_.jpg 350w, https://cdn.swbpg.com/t/12802/a23beccbdc80428b9c5ca9a1474f7b65_s=660x_.jpg 660w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--60" name={"autobusy"} layout={"l1"}>
        </Column>


        <Column className="pb--60 pt--60" name={"bookxa2ip0p"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/12802/e7c05ddcb87d41f78886fe3b292d5bfb_s=660x_.jfif"} svg={false} ratio={"1:1"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/12802/e7c05ddcb87d41f78886fe3b292d5bfb_s=350x_.jfif 350w, https://cdn.swbpg.com/t/12802/e7c05ddcb87d41f78886fe3b292d5bfb_s=660x_.jfif 660w, https://cdn.swbpg.com/t/12802/e7c05ddcb87d41f78886fe3b292d5bfb_s=860x_.jfif 860w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/12802/5b1ef3546c384178995e3f4390231c39_s=660x_.jfif"} svg={false} ratio={"1:1"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/12802/5b1ef3546c384178995e3f4390231c39_s=350x_.jfif 350w, https://cdn.swbpg.com/t/12802/5b1ef3546c384178995e3f4390231c39_s=660x_.jfif 660w, https://cdn.swbpg.com/t/12802/5b1ef3546c384178995e3f4390231c39_s=860x_.jfif 860w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/12802/a9a28149191544898ab27f92b12a9620_s=660x_.jfif"} svg={false} ratio={"1:1"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/12802/a9a28149191544898ab27f92b12a9620_s=350x_.jfif 350w, https://cdn.swbpg.com/t/12802/a9a28149191544898ab27f92b12a9620_s=660x_.jfif 660w, https://cdn.swbpg.com/t/12802/a9a28149191544898ab27f92b12a9620_s=860x_.jfif 860w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cena"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Ceník"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Vzhledem k  cenám pohonných hmot, které se neustále mění, nelze stanovit jednotnou pevnou sazbu za kilometr.\n\nCeny vypočítáváme na základě Vámi zaslané poptávky (specifikace cesty) podle předpokládaných ujetých kilometrů plus další poplatky (mýtné, parkovné).\n\nU autobusové přepravy na delší vzdálenost většinou domlouváme s našimi zákazníky smluvní ceny.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"8bf6ppgppgy"}>
          
          <FullmapWrap className="pb--20 pt--20" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"Křivoklátská 170, 273 51 Unhošť"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"11"} place={"Křivoklátská 170 Unhošť 273 51"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="mb--60" name={"cenik-3"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"kontakt-1"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Provozovna"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">Křivoklátská 170<br>273 51 Unhošť<br>Česká republika</span>"}>
              </Text>

              <Subtitle className="subtitle-box mt--16" style={{"maxWidth":300}} content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">E-mail: </span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"info@kovybus.cz"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Telefonní kontakt:</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"+420 723 613 413<br>+420 792 272 842<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"IČ:"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">09163450</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"DIČ:"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">CZ09163450</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style3 mt--0" name={"kontakt-2"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}